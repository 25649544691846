.c-mainEvent {
    margin: 120px auto 0px;
    width: 90%;
    max-width: 1200px;
    position: relative;
    @include m767 {
        margin: 60px auto;
    }
    &:after, &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 120px;
        pointer-events: none;
    }
    &:before {
        top: -60px;
        left: -40px;
        border-left: 3px solid $yellow;
        border-top: 3px solid $yellow;
        @include m767 {
            top: -40px;
            left: -20px;
        }
    }
    &:after {
        right: -40px;
        bottom: -60px;
        border-right: 3px solid $yellow;
        border-bottom: 3px solid $yellow;
        @include m767 {
            bottom: -20px;
            right: -20px;
        }
    }
    &-title {
        @include title;
        font-weight: 400;
        font-size: 26px;
        margin-bottom: 60px;
        @include m767 {
            margin-bottom: 30px;
            font-size: 20px;
        }
    }
    .c-event {
        margin-bottom: 60px;
        @include m767 {
            margin-bottom: 30px;
        }
    }
}