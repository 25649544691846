
.c-header {
    text-transform: uppercase;
    font-weight: 400;
    color: $dark;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    box-sizing: content-box;
    position: sticky;
    top: 0;
    background: $white;
    z-index: 3;
    height: $header-height;
    transition: height 0.3s ease;
    @include m767 {
        padding: 10px 0px;
        font-size: 18px;
    }
    &__burger {
        cursor: pointer;
        width: 30px;
        height: 20px;
        position: fixed;
        right: 20px;
        top: 25px;
        z-index: 11;
        display: none;
        @include m767 {
            display: block;
        }
        &::before, &:after {
            content: "";
            display: inline-block;
            background: $black;
            height: 2px;
            position: absolute;
            width: 100%;
            transform: translate(0%, -50%);
            transition: all 0.2s ease;
        }
        &:before {
            top: 0%;
        }
        &:after {
            top: 100%;
        }
        span {
            height: 2px;
            width: 100%;
            position: absolute;
            background: $black;
            top: 50%;
            transform: translate(0%, -50%);
            transition: all 0.2s ease;
        }
       
    }
    &__nav {
        width: 100%;
        height: 100%;
        margin: 0px 40px 0px;
        @include m767 {
            margin: 0px 20px;
        &.open {
            .c-header__burger {
                span {
                    transform: translate(50%, -50%);
                    opacity: 0;
                    transition: all 0.2s ease;
                }
                &:before {
                    transform: translate(0%, -50%) rotate(45deg);
                    top: 50%;
                    transition: all 0.2s ease;
                }
                &:after {
                    transform: translate(0%, -50%) rotate(-45deg);
                    top: 50%;
                    transition: all 0.2s ease;

                }
            }
            .c-header__menu{
                    display: flex;
                    animation: slideIn 0.3s ease forwards;
                    background: $yellow;
                    &:after {
                        content: "";
                        background: $white;
                        position: absolute;
                        animation: slideIn 0.6s ease forwards;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        left: -30px;
                        right: 0;
                        z-index: -3;
                    } 
                }
            }
        }
        @keyframes slideIn {
            from {
                left: 100%;
            } to {
                left: 0%;
            }
        }
        @keyframes slideOut {
            from {
                left: 0%;
            } to {
                left: 100%;
            }
        }
    }
    &__menu {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        @include m767 {
            position: fixed;
            animation: slideOut 0.3s ease forwards;
            background: $white;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
        &-item {
            margin-right: 25px;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            color: $black;
            transition: color 0.1s ease;
            @include m767 {
                margin-right: 0px;
                margin-bottom: 30px;
            }
            a {
                &:before {
                    position: absolute;
                    content: '';
                    width: 0%;
                    transform: translate(0%, -50%);
                    height: 3px;
                    background: $yellow;
                    z-index: -1;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    transition: width 0.3s ease;
                }
                &:hover{
                    transition: color 0.1s ease;
                    &:before {
                        width: 100%;
                        transition: width 0.3s ease;
                    }
                }
                &.active {
                    cursor: default;
                    &:before {
                        width: 100%;
                    }
                }
            }
            &:last-child {
                margin-right: 0;
                &:after {
                    display: none;
                }
            }
        }
    }
}