.t-contact {
    margin: 0px 40px 60px;
    @include m767 {
        margin: 0px 20px 50px;
    }
    &__container {
        @include animationRight;
        margin: 120px auto 0px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        @include m1200{
            display: block;
        }
        @include m991 {
            margin: 60px auto;
        }
        @include m767 {
            margin: 40px auto;
        }
        &-title {
            @include title;
        }
        &-infos {
            width: 40%;
            margin-bottom: 50px;
            @include m1200{
                width: 100%;
            }
            h1, h2, h3, h4, h5, h6 {
                @include title;
                margin-bottom: 40px;
            }
            p {
                @include text;
                margin-bottom: 15px;
                strong {
                    font-weight: 600;
                }
                em {
                    font-style: italic;
                }
            }
        }
        &-form {
            width: 55%;
            @include m1200{
                width: 100%;
            }
        }
    }
}