$circle-size: 15px;
$circle-size-mobile: 10px;
.c-project {
    position: relative;
    &.hide {
        display: none;
    }
    &-infos {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.6);
        inset: 0;
        z-index: 1;
        opacity: 0;
        color: $white;
        transition: opacity 0.2s ease;
        padding: 12px 20px;
        @include m767 {
            padding: 10px;
        }
        &-title {
            text-transform: uppercase;
            color: $white;
            font-size: 18px;
            line-height: 1.2;
            font-weight: 500;
        }
        &-text {
            color: $white;
            font-size: 14px;
            line-height: 1.2;
            font-weight: 400;
        }
    }
    &-avaibility {
        position: absolute;
        z-index: 2;
        bottom: $circle-size;
        right: $circle-size;
        padding: 2px 25px 2px 15px;
        @include m767 {
            bottom: $circle-size-mobile;
            right: $circle-size-mobile;  
            padding: 2px 15px 2px 10px;
        }
        &.available {
            &:after {
                background-color: $green;
            }
        }
        p {
            margin-right: 10px;
            opacity: 0;
            font-size: 16px;
            line-height: 1.4;
            font-weight: 400;
            @include m767 {
                font-size: 12px;
                line-height: 1.1;
            }
        }
        &:after {
            content: "";
            position: absolute;
            width: $circle-size;
            height: $circle-size;
            background-color: $red;
            border-radius: 50%;
            display: inline-block;
            top: 50%;
            right: 5px;
            transform: translate(0%, -50%);
            @include m767 {
                width: $circle-size-mobile;
                height: $circle-size-mobile;
            }
        }
    }
    &:hover {
        .c-project-infos {
            opacity: 1;
            transition: opacity 0.2s ease;
        }
        .c-project-avaibility {
            color: $white;
            p {
                opacity: 1;
            }
        }
        img {
          transition: transform 0.2s ease;
          transform: scale(1.05);
        }
      }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 0.2s ease;
        border-radius: 2px;
    }
}