.c-contactForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    &__input {
        width: 48%;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        margin-top: 40px;
        margin-bottom: 40px;
        @include m767 {
            margin-bottom: 20px;
        }
        &.full {
            width: 100%;
            justify-content: center;
        }
        &__container {
            position: relative;
            width: 100%;
            &.filled {
                .c-contactForm__input-label {
                    top: -15px;
                    font-size: 12px;
                }
                input, textarea {
                    padding: 12px 20px;
                }
            }
        }
        &-label {
            position: absolute;
            top: 18px;
            left: 0;
            padding: 0px 10px;
            transition: top 0.3s ease, font-size 0.3s ease;
            transform: translate(0%, -50%);
            @include text;
            @include m767 {
                top: 14px;
            }
        }
        &-error {
            @include text;
            color: $red;
            position: absolute;
            left: 0;
            bottom: -30px;
        }
        input, textarea {
            width: 100%;
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
            padding: 5px 20px;
            transition: padding 0.3s ease;
            border-radius: 0px;
            @include text;
            &:focus {
                transition: padding 0.3s ease;
                padding: 12px 20px;
                & + .c-contactForm__input-label {
                    top: -15px;
                    font-size: 12px;
                    transition: top 0.3s ease, font-size 0.3s ease;
                }
            }
        }
        textarea {
            resize : none;
            height: 250px;
            transition: height 0.3s ease;
            @include m767 {
                height: 150px;
            }
            &:focus {
                height: 310px;
                transition: height 0.3s ease;
                @include m767 {
                    height: 210px;
                }
            }
        }
    }
    &__success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px auto;
        @include m767 {
            margin: 60px auto;
        }
        &-title {
            text-align: center;
            @include title;
            margin-bottom: 50px;
            @include m767 {
                margin-bottom: 30px;
            }
        }
    }
}