.c-loader {
    display: block;
    margin: 80px auto 30px;
    position: relative;
    width: 80px;
    height: 80px;
    @include m767 {
      margin: 30px auto;
    }
    div {
        position: absolute;
        border: 4px solid $yellow;
        opacity: 1;
        animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        &:nth-child(2) {
            animation-delay: -0.5s;
        }
    }
    &-text {
        position: absolute;
        bottom: -25px;
        left: 50%;
        font-weight: 400;
        transform: translate(-50%, 0%);
        font-size: 14px;
        text-transform: uppercase;
    }
  }
  @keyframes loading {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  