.c-event {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    @include m767 {
        display: block;
        max-width: 500px;
        margin: 0 auto;
    }
    &__visuel {
        width: 50%;
        height: 350px;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        @include m767 {
            width: 100%;
            height: 300px;
            background-position: center;
        }
    }
    &__description {
        width: 50%;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @include m767 {
            width: 100%;
            margin: 0 auto;
            justify-content: center;
            text-align: center;
        }
        &-title {
            @include title;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @include m767 {
                margin-top: 20px;
            }
        }
        &-date {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            @include m767 {
                justify-content: center;
            }
            &-item {
                font-size: 14px;
                margin-top: 5px;
                @include m767 {
                    font-size: 12px;
                    line-height: 1.4;
                }
            }
        }
        &-text {
            @include text;
            margin: 20px 0px;
            // display: -webkit-box;
            // -webkit-line-clamp: 6;
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            // text-overflow: ellipsis;
            strong {
                font-weight: 500;
            }

        } 
    }
    .button-event {
        margin-top: auto;
        margin-left: auto;
    }
}