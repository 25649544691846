// MEDIA QUERIES  
@mixin m376 {
    @media screen and (max-width: 376px) {
        @content;
    }
}
@mixin m767 {
    @media screen and (max-width: 767px) {
        @content;
    }
}
@mixin m991 {
    @media screen and (max-width: 991px) {
        @content;
    }
}
@mixin m1200 {
    @media screen and (max-width: 1200px) {
        @content;
    }
}
@mixin m1800 {
    @media screen and (max-width: 1800px) {
        @content;
    }
}

@mixin title {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
    text-transform: uppercase;
    @include m767 {
        font-size: 16px;
        line-height: 1.2;
    }
}

@mixin text {
    font-size: 16px;
    line-height: 1.35;
    font-weight: 300;
    @include m767 {
        font-size: 12px;
    }
}

@mixin animationRight {
    animation: slideInRight 0.3s ease forwards;
    transform: translateX(0%)!important;
    @include m767 {
        animation: none;
    }
    @keyframes slideInRight {
        from {
            transform: translateX(100%);
        } to {
            transform: translateX(0%);
        }
    }
}
@mixin animationLeft {
    animation: slideInLeft 0.3s ease forwards;
    transform: translateX(0%)!important;
    @include m767 {
        animation: none;
    }
    @keyframes slideInLeft {
        from {
            transform: translateX(-100%);
        } to {
            transform: translateX(0%);
        }
    }
}