.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
    @include m767 {
        padding: 0 70px;
    }
    &--mini {
        max-width: 520px;
        @include m767 {
            max-width: 850px;
        }
    }
}