.c-projectsList {
  
  &-container{
    color: $black;
    background-image: linear-gradient(270deg,#fff 49.9%,$black 50%);
  } 
  &-title {
    font-family: $main-font;
    font-size: 90px;
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 50px;
    color: $white; 
    mix-blend-mode: difference;
  }
  &__gallery {
    width: 100%;
    &.hide {
      opacity: 0;
    }
    &-item {
      width: calc(100% / 4 - 1rem);
      margin: 0.5rem;
      overflow: hidden;
      cursor: pointer;
      @include m1800 {
        width: calc(100% / 3 - 1rem);
      }
      @include m767 {
        width: calc(100% / 2 - 1rem);
      }
      @include m376 {
        width: 100%;
        margin: 0.5rem 0;
      }
    }
  }
  &-btn {
    text-align: center;
    margin: 20px auto;
    position: relative;
    z-index: -1;
  }
}
  

  
  
  
 
  