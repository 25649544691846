.c-title-sticky {
    font-family: $main-font;
    font-size: 90px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 30px;
    color: $black; 
    position: sticky;
    top: 0;
    z-index: 5;
    min-height: 110px;
    transition: font-size 0.2s ease;
    width: fit-content;
    &.top {
        font-size: 30px;
        padding-top: calc(#{$header-height} / 2);
        transition: font-size 0.2s ease;
        @include m767 {
            font-size: 20px;
        }
    }
    @include m991 {
        font-size: 60px;
    }
    @include m767 {
        position: fixed;
        min-height: 80px;
        font-size: 20px;
        padding-top: calc(#{$header-height} / 2);
        transition: font-size 0.2s ease;
    }
    strong {
        background: $yellow;
        color: $white;
        font-weight: 700;
        padding: 0px 10px;
    }
}