// Utils
@import './useful/reset';
@import './useful/vars';
@import './useful/mixins';
@import './useful/layout';
// Components
@import './components/header';
@import './components/footer';
@import './components/loader';
@import './components/titleSticky';
@import './components/projectsList';
@import './components/project';
@import './components/popinProject';
@import './components/event';
@import './components/mainEvent';
@import './components/eventsList';
@import './components/contactForm';
// Templates
@import './templates/homepage';
@import './templates/evenements';
@import './templates/contact';

.app {
    min-height: 100vh;
    font-family: $main-font;
    display: flex;
    flex-direction: column;
}


