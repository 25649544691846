.c-footer {
    width: 100%;
    padding: 30px 0px;
    background: $light-grey;
    margin-top: 60px;
    margin-top: auto;
    @include m767 {
        padding: 20px 0px 0px;
        // margin-top: 50px;
    }
    &__container {
        margin: 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include m767 {
            margin: 0 20px;
            flex-wrap: wrap;
        }
        &-item {
            display: flex;
            font-size: 12px;
            color: $grey;
            @include m767 {
                justify-content: center;
                width: 100%;
                font-size: 10px;
                margin-bottom: 20px;
                &:last-child{
                    order: -1;
                }
            }
            &.footer-infos {
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                @include m767 {
                    order: -2;
                }
                p:first-child {
                    display: flex;
                    &::after{
                        content: "";
                        margin: 0px 15px;
                        display: inline-block;
                        height: 100%;
                        width: 1px;
                        background-color: $grey;
                    }
                }
            }
            &-logo {
                // max-width: 25px;
                height: auto;
            }
        }
    }
}