@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

$main-font: 'Montserrat', sans-serif;

$white : #ffffff;
$black: #000000;
$grey: #5f5e5e;
$light-grey: #f6f6f6; 
$dark: #231123;
$purple: #82204A;
$red: #ea4335;
$green: #45c663;
$yellow: #ffdc11;

$header-height : 50px;

.custom-btn {
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    &.btn-border {
        width: 115px;
        height: 40px;
        line-height: 42px;
        padding: 0;
        border: none;
        &:hover {
            &:before {
                transition: all 0.1s 0.2s ease;
                height: 100%;
            }
            &:after {
                transition: all 0.1s ease;
                height: 100%;
            }
        }
        span {
            position: relative;
            display: block;
            text-align: center;
            width: 100%;
            height: 100%;
            font-size: 18px;
            font-weight: 400;
            &:hover {
                &:before {
                    width: 100%;
                }
            }
            &:before, &:after {
                position: absolute;
                content: "";
                background: $yellow;
                height: 3px;
                left: 0;
            }
            &:before {
                top: 0;
                width: 0%;
                transition: all 0.1s 0.1s ease;
            }
            &:after {
                bottom: 0;
                width: 100%;
            }
        }
        &:before, &:after {
            position: absolute;
            content: "";
            height: 0%;
            width: 3px;
            background: $yellow;
        }
        &:before {
            right: 0;
            top: 0;
            transition: all 0.1s ease;
        }
        &:after {
            left: 0;
            bottom: 0;
            transition: all 0.1s 0.2s ease;
        }
    }
}