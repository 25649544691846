.c-popinProject {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: $black, $alpha: 0.7);
    z-index: 999;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s 0.2s linear;
    &.open {
        pointer-events: all;
        opacity: 1;
        transition: opacity 0.2s linear;
        .c-popinProject__container {
            width: 90%;
            height: 90%;
            max-width: 1700px;
            transition: all 0.2s ease;
            @include m767 {
                width: 85%;
                height: 75%;
            }
            &:after{
                width: 0%;
                transition: width 0.4s 0.2s ease;
            }
            &:before{
                width: 0%;
                transition: width 0.4s 0.3s ease;
            }
            &-title {
                width: 100%;
                transition: all 0.5s 0.4s ease;
                padding: 15px 20px;
                @include m767 {
                    padding: 8px 12px;
                }
            }
        }
    }
    &__close-btn {
        position: absolute;
        top: 1%;
        right: 1%;
        transition: transform 0.2s ease;
        width: 35px;
        height: 35px;
        cursor: pointer;
        @include m767 {
            top: 2%;
        }
        &:hover {
            transform: rotate(90deg);
            transition: transform 0.2s ease;
        }
        &:after, &:before {
            content: "";
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            top: 50%;
            left: 50%;
            z-index: 2;
            background-color: $white;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
    &__container {
        width: 0%;
        height: 0%;
        transition: all 0.2s ease;
        background: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        } 
        &:before {
            background: $yellow;
        }
        &:after {
            background: $white;
        }
        &-title {
            background-color: $yellow;
            position: absolute;
            color: $white;
            z-index: 1;
            top: -3%;
            left: -3%;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 24px;
            display: flex;
            align-items: center;
            width: 0%;
            max-width: fit-content;
            padding: 15px 0px;
            overflow: hidden;
            @include m767 {
                font-size: 18px;
                padding: 8px 0px;
                left: -3%;
            }
        }
    }
    &__visuel {
        width: 95%;
        height: 95%;
        margin: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &__size, &__available {
        position: absolute;
        @include text;
        bottom: 0;
        background: rgba($white, 0.6);
        padding: 10px 15px;
        @include m767 {
            padding: 8px 12px; 
        }
        strong {
            font-weight: 600;
        }
    }
    &__size {
        left: 0;
    }
    &__available {
        right: 0;
    }
}