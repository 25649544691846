.c-eventsList {
    margin: 120px auto 0px;
    @include m767 {
        margin: 60px auto;
    }
    &-title {
        @include title;
        text-align: center;
        img {
            margin: 20px auto 0px;
            height: auto;
            @include m767 {
                width: 75px;
            }
        }
    }
    h3 {
        height: 500px;
        @include m767 {
            height: auto;
        }
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__filters {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 100px;
        @include m767 {
            margin-bottom: 50px;
        }
        &:before, &:after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 1px;
            width: 40px;
            background-color: rgba($grey, 0.2);
            @include m767 {
                width: 20px;
            }
        }
        &:before {
            left: -40px;
            @include m767 {
                left: -20px;
            }
        }
        &:after {
            right: -40px;
            @include m767 {
                right: -20px;
            }
        }
        &-item {
            width: 50%;
            border: 1px solid rgba($grey, 0.2);
            @include title;
            padding: 30px;
            background-color: $light-grey;
            color: $grey;
            transition: box-shadow 0.2s ease, background-color 0.2s ease;
            font-weight: 400;
            @include m767 {
                padding: 15px;
            }
            &:first-child {
                border-right: 0;
            }
            &.active {
                border-bottom: 0px;
                background-color: $white;
                color: $black;
                position: relative;
                box-shadow: 0px -5px 10px -5px rgba($black, 0.1);
                transition: box-shadow 0.2s ease, background-color 0.2s ease;
            }
        }
    }
    &__item {
        margin-bottom: 50px;
        width: 45%;
        margin-right: 40px;
        border-bottom: 2px solid $light-grey;
        padding-bottom: 50px;
        @include m1200 {
            padding-bottom: 0px;
            margin-bottom: 80px;
            border: none;
            width: 100%;
            margin-right: 0px;
            &:nth-child(odd) {
                .c-event {
                    flex-direction: row-reverse;
                    &__description {
                        margin-left: 0;
                        margin-right: 30px;
                    }
                }
            }
        }
        @include m767 {
            margin-bottom: 30px;
        }
    }
    &-btn {
        text-align: center;
        margin: 20px auto;
    }
}