/* Reset box-model
   ========================================================================== */

   * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  ::before,
  ::after {
    box-sizing: inherit;
  }
  
  /* Document
     ========================================================================== */
  
  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -webkit-tap-highlight-color: transparent; /* 3*/
  }
  
  /* Sections
     ========================================================================== */
  
  body {
    // font-family: $main-font;
    font-size: 20px;
    margin: 0;
  }
  
  /**
   * Render the `main` element consistently in IE.
   */
  
  main {
    display: block;
  }
  
  /* Vertical rhythm
     ========================================================================== */
  
  p,
  table,
  blockquote,
  address,
  pre,
  iframe,
  form,
  figure,
  dl {
    margin: 0;
  }
  
  /* Headings
     ========================================================================== */
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
  }
  
  /* Lists (enumeration)
     ========================================================================== */
  
  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  /* Lists (definition)
     ========================================================================== */
  
  dt {
    font-weight: bold;
  }
  
  dd {
    margin-left: 0;
  }
  
  /* Grouping content
     ========================================================================== */
  
  /**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
  
  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
    border: 0;
    border-top: 1px solid;
    margin: 0;
    clear: both;
  }
  
  /**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
  
  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: inherit; /* 2 */
  }
  
  address {
    font-style: inherit;
  }
  
  /* Text-level semantics
     ========================================================================== */
  
  a {
    background-color: transparent;
    text-decoration: none;
    color: inherit;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  img {
    border-style: none;
    display: block;
    max-width: 100%;
    height: auto;
    flex-shrink: 0; // don't stretch img on ie11 with flex box
  }
  
  /* Forms
     ========================================================================== */
  
  button,
  input,
  optgroup,
  select,
  textarea {
    vertical-align: middle;
    color: inherit;
    font: inherit;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    outline: 0;
  }
  
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
  }
  
  button[disabled],
  [type='button'][disabled],
  [type='reset'][disabled],
  [type='submit'][disabled] {
    cursor: default;
  }
  
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    min-width: 0;
  }
  
  legend {
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }
  textarea {
    overflow: auto;
  }
  
  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }
  
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  
  /* Table
     ========================================================================== */
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  caption {
    text-align: left;
  }
  
  td,
  th {
    vertical-align: top;
  }
  
  th {
    text-align: left;
    font-weight: bold;
  }