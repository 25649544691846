.t-evenements {
    margin: 0px 40px 60px;
    @include m767 {
        margin: 0px 20px 50px;
    }
    &.next {
        .c-eventsList {
            @include animationRight;
        }
    }
    &.prev {
        .c-eventsList {
            @include animationLeft;
        }
    }
}